table {
    border: 1px solid $sec-black;

    tr {
        &:nth-child(even) {
            td {
                background-color: #1f2124;
            }
        }

        th {
            background-color: #0D1D2C !important;
            color: $sec-white !important;
        }

        td {
            color: $sec-white !important;
        }

    }

    .sno {
        max-width: 25px;
        text-align: center;
    }

    .date {
        max-width: 50px;

    }

    .name {
        max-width: 30px;
    }

    .amount {
        max-width: 30px;
    }

    .action {
        cursor: pointer;
        max-width: 5px;
        text-align: center;

        img {
            transition: all .6s;
        }

        &:hover {
            img {
                scale: 1.2;
                transition: all .6s;
            }
        }
    }

}