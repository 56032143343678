.modal {
    display: block;;
    color: $sec-black;
    &.show {
        display: block;
    }

    .hide {
        display: none;
    }
}