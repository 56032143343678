nav {
    width: 200px;
    padding: 20px 0 20px 0;
    background-color: $sec-black;
    margin-left: 0;
    transition: all .6s;
    z-index: 9;

    @include mobile {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .slide {
        display: block;
        text-align: right;
        padding-right: 2px;
        cursor: auto;
        margin-bottom: 20px;
        width: 100%;
        border: none;
        background-color: transparent;

        @include mobile {
            display: none;
        }

        img {
            cursor: pointer;
            padding: 5px 10px;
            background-color: $prim-black;

            &:hover {
                opacity: .8;
            }
        }
    }

    @include mobile {
        .hide.show {
            display: none;
        }
    }

    &.toggle {

        @include desktop {
            max-width: 60px !important;
            transition: all .6s;

            .navbar-brand {
                img {
                    max-width: 30px;
                    margin-left: 10px;
                }
            }

            .nav-link {
                font-size: 0;
                transition: all .6s;
            }

            .slide {
                img {
                    transform: rotate(180deg);
                }
            }
        }
    }

    @include mobile {
        position: fixed !important;
        left: 0;
        top: 45px;
        overflow: auto;
        width: 100%;
    }

    &.navbar-expand-sm {
        @media (min-width:768px) {

            display: block;
            width: 100%;
            max-width: 200px;
        }

        .navbar-nav {
            display: block;
            width: 100%;

            @include mobile {
                margin-top: 5px;
                background-color: #161829;

            }
        }
    }

    .brand {
        width: 120px;
        height: 35px;
        z-index: 2;
        display: block;
        position: absolute;
        top: 10px;
        left: 0;
        opacity: .5;
    }

    .navbar-brand {
        background-color: transparent;
        border: none;
        text-align: center;

        @include desktop {
            display: block;
            width: 100%;
            margin-bottom: 20px;
            margin-right: 0;
            border-bottom: 1px solid #585d62;
            padding-top: 0;
            text-align: left;
        }

        img {
            max-width: 100px;
        }

        @include mobile {
            margin-right: 0;
            margin-left: 15px;
        }
    }

    .navbar-toggler {
        @include mobile {
            margin-right: 15px;
            border: none;

            img {
                max-width: 20px;
            }
        }

        &:focus {
            @include mobile {
                outline: none;
                box-shadow: none;
            }
        }
    }

    .nav-item {

        img {
            max-width: 25px;
            margin-right: 10px;
        }

    }

    .nav-link {
        display: flex;
        color: $sec-white;
        padding-left: 20px !important;
        transition: all .6s;

        &.active {
            background-color: #2a2d30;
            color: $sec-white !important;
        }

        &:hover {
            color: $prim-white !important;

        }
    }

    hr {
        border-top: 1px solid #8c97a3;
        margin: 10px 0;
    }
}